"use client"

import { useEffect, useState } from "react"

const useBusy = (delay?: number, callback?: () => void) => {
  const [busy, setBusy] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setBusy(false)
      callback && callback()
    }, delay ?? 10)

    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return busy
}

export default useBusy
