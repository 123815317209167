export interface CalculateTaxValueParams {
  taxableRate?: number
  basePrice: number
  taxPercentage: number
  isNewTaxCalculationEnabled?: boolean
  roundingMethod?: "FLOOR" | "ROUND"
}

const calculateTaxValue = ({
  taxableRate = 1,
  basePrice,
  taxPercentage,
  isNewTaxCalculationEnabled,
  roundingMethod,
}: CalculateTaxValueParams) => {
  if (isNewTaxCalculationEnabled) {
    const rounding = roundingMethod === "ROUND" ? Math.round : Math.floor

    return rounding(taxableRate * (basePrice * (taxPercentage / 100)))
  }

  return Math.floor(basePrice * (taxPercentage / 100))
}

export default calculateTaxValue
