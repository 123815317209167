'use client'

import React, { useState, useRef, useEffect } from 'react'
import Tooltip from '../Tooltip'
import { cn } from 'shared-utils'

interface TruncatableTextProps {
  text: string
  className?: string
  maxLines?: number
}

const TruncatableText: React.FC<TruncatableTextProps> = ({ text, className = '', maxLines = 2 }) => {
  const [isTruncated, setIsTruncated] = useState(false)
  const textRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const checkTruncation = () => {
      const element = textRef.current
      if (element) {
        const isTextTruncated = element.scrollHeight > element.clientHeight
        setIsTruncated(isTextTruncated)
      }
    }

    checkTruncation()

    /**
     * Simple debounced resize listener
     * We debounce the resize event to prevent performance issues when:
     * 1. Many TruncatableText components are rendered on the same page
     * 2. Rapid resize events would trigger excessive recalculations
     * The 100ms delay provides a good balance between responsiveness and performance
     */

    let timeoutId: NodeJS.Timeout
    const handleResize = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(checkTruncation, 100)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      clearTimeout(timeoutId)
      window.removeEventListener('resize', handleResize)
    }
  }, [text])

  return isTruncated ? (
    <Tooltip title={text} placement="bottom">
      <div ref={textRef} className={cn(`line-clamp-${maxLines}`, 'overflow-hidden', className)}>
        {text}
      </div>
    </Tooltip>
  ) : (
    <div ref={textRef} className={cn(`line-clamp-${maxLines}`, 'overflow-hidden', className)}>
      {text}
    </div>
  )
}

export default React.memo(TruncatableText)
