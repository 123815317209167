import { Extension } from '@tiptap/core'
import { Plugin } from '@tiptap/pm/state'

const SpaceControl = Extension.create({
  name: 'spaceControl',
  addOptions() {
    return {
      onDebounceStatusChange: null,
    }
  },
  addProseMirrorPlugins() {
    let timeoutId: NodeJS.Timeout | null = null
    const { onDebounceStatusChange } = this.options

    return [
      new Plugin({
        props: {
          handlePaste(view, event) {
            event.preventDefault()
            let pastedText = event.clipboardData?.getData('text') || ''

            pastedText = pastedText.trim()

            const { from } = view.state.selection
            view.dispatch(view.state.tr.insertText(pastedText, from))

            return true
          },

          handleTextInput: (view, from, __, text) => {
            const isAtStart = from === 1

            if (isAtStart && text === ' ') {
              return true
            }

            if (onDebounceStatusChange) {
              onDebounceStatusChange?.(true)
            }

            const shutDownDebounceStatus = () => {
              if (onDebounceStatusChange) {
                onDebounceStatusChange?.(false)
              }
            }

            if (timeoutId) {
              clearTimeout(timeoutId)
            }

            timeoutId = setTimeout(() => {
              const { state, dispatch } = view
              const { doc } = state

              const lastNode = doc.lastChild
              if (!lastNode || !lastNode.isTextblock) {
                shutDownDebounceStatus()
                return
              }

              const text = lastNode.textContent
              const match = text.match(/\s+$/)
              if (!match) {
                shutDownDebounceStatus()
                return
              }

              const spacesToRemove = match[0].length
              const posEnd = doc.content.size
              const posStart = posEnd - spacesToRemove - 1

              if (spacesToRemove > 0) {
                dispatch(state.tr.delete(posStart, posEnd))
              }

              shutDownDebounceStatus()
            }, 2000)

            return false
          },
        },
      }),
    ]
  },
})

export default SpaceControl
