import calculateTaxValue, { CalculateTaxValueParams } from "./calculateTaxValue"

interface BaseParams extends Omit<CalculateTaxValueParams, "taxPercentage"> {}

interface WithTaxPercentage extends BaseParams {
  /**
   * taxes rates
   */
  taxes: number[]
  taxesValues?: never
}

interface WithTaxValue extends BaseParams {
  taxesValues: number[]
  /**
   * taxes rates
   */
  taxes?: never
}

type CalculatePriceAfterTaxParams = WithTaxValue | WithTaxPercentage

const calculatePriceAfterTax = ({
  basePrice,
  taxes,
  taxableRate = 1,
  isNewTaxCalculationEnabled,
  roundingMethod,
  taxesValues,
}: CalculatePriceAfterTaxParams) => {
  if (taxesValues) {
    return basePrice + taxesValues.reduce((prev, curr) => prev + curr, 0) ?? 0
  }

  return (
    basePrice +
    taxes.reduce(
      (prev, curr) =>
        prev +
        calculateTaxValue({
          taxableRate,
          basePrice,
          taxPercentage: curr,
          isNewTaxCalculationEnabled,
          roundingMethod,
        }),
      0
    )
  )
}

export default calculatePriceAfterTax
