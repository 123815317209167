export default function decimalToFraction(
  decimal: number,
  precision = 0.000001
): string {
  if (!Number.isFinite(decimal)) {
    throw new Error("Input must be a finite number")
  }
  if (!Number.isFinite(precision) || precision <= 0) {
    throw new Error("Precision must be a positive number")
  }

  if (Number.isInteger(decimal)) {
    return `${decimal}/1`
  }

  const sign = Math.sign(decimal)
  const absDecimal = Math.abs(decimal)

  let bestNumerator = 1
  let bestDenominator = 1
  let bestError = Math.abs(absDecimal - bestNumerator / bestDenominator)

  const MAX_DENOMINATOR = 100

  for (let d = 1; d <= MAX_DENOMINATOR; d++) {
    const minN = Math.floor(absDecimal * d)
    const maxN = Math.ceil(absDecimal * d)

    for (let n = minN; n <= maxN; n++) {
      const currentFraction = n / d
      const currentError = Math.abs(absDecimal - currentFraction)

      if (currentError < bestError) {
        bestError = currentError
        bestNumerator = n
        bestDenominator = d

        if (bestError < precision) {
          break
        }
      }
    }

    if (bestError < precision) {
      break
    }
  }

  bestNumerator *= sign
  return `${bestNumerator}/${bestDenominator}`
}
