import { decimalToFraction } from 'shared-utils'

const getPPNFormulaText = ({
  hasPpnBm,
  taxableRate,
  price,
  taxablePrice,
}: {
  hasPpnBm: boolean
  taxableRate: number
  price?: number
  taxablePrice?: number
}) => {
  const hasCustomTaxable = price !== undefined && taxablePrice !== undefined && taxablePrice !== price

  return hasPpnBm || hasCustomTaxable || taxableRate >= 1
    ? 'Harga Produk'
    : `(${decimalToFraction(taxableRate)} x Harga Produk)`
}

const generateTaxFormulaLabel = ({
  ppnPercentage,
  ppnBmPercentage,
  taxableRate,
}: {
  ppnPercentage: number
  ppnBmPercentage: number
  taxableRate: number
}) => {
  const map: Map<string, string> = new Map()

  if (ppnPercentage) {
    map.set('PPN', `PPN = ${getPPNFormulaText({ hasPpnBm: ppnBmPercentage > 0, taxableRate })} x ${ppnPercentage}%`)
  }

  if (ppnBmPercentage) {
    map.set('PPnBM', `PPnBM = Harga Produk x ${ppnBmPercentage}%`)
  }

  return map
}

export default generateTaxFormulaLabel
