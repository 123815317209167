'use client'

import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Placeholder from '@tiptap/extension-placeholder'
import CharacterCount from '@tiptap/extension-character-count'
import { EditorContent, useEditor } from '@tiptap/react'
import { cx } from 'class-variance-authority'
import Toolbar from './Toolbar'
import SpaceControl from './helpers/SpaceControl'

type TRichTextEditorProps = {
  getCharacterCount?: (count: number) => void
  limit?: number | null
  onChange?: (richText: string) => void
  placeholder?: string
  styles?: {
    wrapper?: string
    editor?: string
    placeholder?: string
  }
  value?: string
  variant?: 'normal' | 'error'
  mode?: 'nodeSize' | 'textSize'
  shouldRemoveWhiteSpaces?: boolean
  onDebounceStatusChange?: (isDebounceRunning: boolean) => void
}

const RichTextEditor = ({
  onChange,
  placeholder,
  styles,
  value,
  variant,
  getCharacterCount,
  limit = null,
  mode,
  shouldRemoveWhiteSpaces,
  onDebounceStatusChange,
}: TRichTextEditorProps) => {
  const isError = variant === 'error'
  const withSpaceControl = shouldRemoveWhiteSpaces ? [SpaceControl.configure({ onDebounceStatusChange })] : []
  const editor = useEditor({
    extensions: [
      Underline,
      CharacterCount.configure({
        limit,
        mode,
      }),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
      }),
      Placeholder.configure({
        placeholder: placeholder || 'Write something …',
        emptyNodeClass: styles?.placeholder,
      }),
      ...withSpaceControl,
    ],
    onUpdate: ({ editor: content }) => {
      const value = content.isEmpty ? '' : content.getHTML()
      getCharacterCount?.(content.storage.characterCount.characters({ mode }))
      onChange?.(value)
    },
    content: value,
    editorProps: {
      attributes: {
        class: cx(styles?.editor, 'min-h-[150px] prose prose-sm m-5 focus:outline-none'),
      },
    },
  })

  if (!editor) {
    return null
  }

  return (
    <div
      className={cx(
        styles?.wrapper,
        isError ? 'border-error500 focus-within:border-error500' : 'border-tertiary100 focus-within:border-tertiary200',
        'group h-fit w-full rounded-4 border border-solid',
      )}
    >
      <Toolbar editor={editor} isError={isError} />
      <EditorContent editor={editor} />
    </div>
  )
}

export default RichTextEditor
